import * as React from "react"

import AnimationWrapper from "../components/animationWrapper"

import "./contactForm.css"

const ContactForm = ( { title, fields, btnStyle }) => {

  // const [subject,setSelect]= React.useState();

  // const handleCapacity=(e)=>{
  //   setSelect(e.target.value);
  // }

    return (
      <AnimationWrapper>
        <form
          name="contact"
          method="POST"
          action="/success"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <div className="form-container">
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="subject" value={`Formularz kontaktowy ${title}`} />
            <label className="hidden">
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
            {fields.includes("name") &&
              <label htmlFor="form-field-name" className="contact-form-label">Imię i nazwisko
                <input type="text" name="name" id="form-field-name" className="contact-form-input" title="* Imię i nazwisko" required="required"></input>
              </label>
            }
            {fields.includes("email") &&
              <label htmlFor="form-field-email" className="contact-form-label">E-mail
                <input type="email" name="email" id="form-field-email" className="contact-form-input " title="* E-mail" required="required"></input>
              </label>
            }
            {fields.includes("phone") &&
              <label htmlFor="form-field-tel" className="contact-form-label">Nr tel.
                <input type="tel" name="tel" id="form-field-phone" className="contact-form-input " title="* Nr tel."></input>
              </label>
            }
            {/* {fields.includes("subject") &&
              <label htmlFor="form-field-subject" className="contact-form-label">Temat
                <select name="subject" id="form-field-subject" className="contact-form-input" title="Temat" value={subject} onChange={handleCapacity}>
                  <option value="default" selected="true" disabled="disabled">&nbsp;</option>
                  <option value="zapytanie-ofertowe">Zapytanie ofertowe</option>
                  <option value="obsluga-zamowienia">Obsługa zamówienia</option>
                  <option value="inne">Inne</option>
                </select>
              </label>
            } */}
            {fields.includes("message") &&
              <label htmlFor="form-field-message" className="contact-form-label">Wiadomość
                <textarea className="contact-form-textarea" name="message" id="form-field-message" rows="3" required="required" title="* Wiadomość"></textarea>
              </label>
            }
            <button type="submit" className={`contact-form-submit-btn ${btnStyle}`} id="contact-form-submit-btn">Wyślij</button>
          </div>
        </form>
      </AnimationWrapper>
    )
  }

export default ContactForm